<script>
import BaseButton from '/~/components/base/button/base-button.vue'

export default {
  name: 'checkout-coupon-tag',
  components: {
    BaseButton,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    removing: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div
    class="relative inline-flex h-10 items-center justify-center self-start overflow-hidden rounded-3xl border bg-primary-lightest"
  >
    <span class="pl-5 pr-2.5 text-sm font-bold uppercase">
      <slot>
        {{ text }}
      </slot>
    </span>
    <base-button
      class="mr-[5px] p-1.5 text-primary"
      size="md"
      icon="plain/close"
      :loading="removing"
      @click="$emit('remove')"
    />
  </div>
</template>
